/** HTML Elements*/
body {
  padding-top: 3rem;
}

p {
	margin-bottom: 0.5em;
}


.policyContainer {
	padding-top: 3em;
}

p.todoLink {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

img.linkLogo {
	width: 1em;
	height: 1em;
}

/** Nav, Consent, and Footer */
.consent-alert {
	position: fixed;
	bottom: 15px;
	left:2%;
	width: 96%;
	text-align: center;
}

footer.footer {
	margin-top: 2em;
	margin-bottom: 1em;
}

.footer .container {
	text-align: center;
}

.todoList {
	margin-top: 2em;
}

/** Upgrade Alert */
.upgradeAlert {
	top: 5rem;
	left: 50%;
	position: fixed;
	transform: translate(-50%, 0px);
	z-index: 9999;
}

/** List Display */
/* .resetButton {
	position: absolute;
	bottom: 0px;
	right: 0;
	margin-bottom: 8px;
} */

.stickyHeading{
  position: -webkit-sticky;
  position: sticky;
	top: 4rem;
	z-index: 100;
	background-color: #272b30;
	padding-top: 0.5em;
}

/**
Item tag badges
*/
span.tagbadge {
	margin-right: 0.5em;
}
span.dow {
	color: black;
	background-color: purple !important;
}
span.dom {
 color: black;
 background-color: orchid !important;
}
span.doh {
	color: black;
	background-color: coral !important;	
}
span.dol {
	color: black;
	background-color: chocolate !important;
}
span.goldsaucer {
	color: black;
	background-color: gold !important;
}
span.other {
	color: black;
	background-color: lightgray !important;
}
span.custom {
	color: black;
	background-color: lightseagreen !important;
}

/** Changelog */
.changeLog h4 {
	font-size: larger;
}